import {useEffect} from "react";

export default function FilterCheckBox(props) {

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    useEffect(() => {
        const checkbox = document.querySelector(`#${props.tag}_filter`)
        checkbox.addEventListener('change', (event) => {
            props.toggleAction(props.tag, checkbox.checked)
        })
    }, []);

    return (
        <div>
            <input type={'checkbox'} name={props.tag} id={props.tag + "_filter"} value={props.tag}
                   className={'filter-checkbox m-1'}/>
            <label htmlFor={props.tag + "_filter"}>{props.toTitleCase ? titleCase(props.label) : props.label}</label>
        </div>
    )
}