import logo from '../../images/DEAL LOGO.svg'
import NavBarUserProfiler from "./NavBarUserProfiler";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import FullscreenMenu from "./FullscreenMenu";

export default function HeadMenu() {

    const [isMenuShow, setIsMenuShow] = useState(false)

    useEffect(() => {
        const div = document.querySelector('#headmenuMenu');


    }, []);

    function HideWhenClickAway(e) {
        const menu = document.querySelector('#headmenuMenu')
        const withinBoundaries = e.composedPath().includes(menu);

        if (!withinBoundaries) {
            menu.classList.add('hidden') // скрываем элемент т к клик был за его пределами
            document.removeEventListener('click', HideWhenClickAway)
        }
    }

    const toogleMenu = () => {
        const menu = document.querySelector('#headmenuMenu')


        if (menu.classList.contains('hidden')) {
            menu.classList.remove('hidden')
            setTimeout(() => {
                document.addEventListener('click', HideWhenClickAway)
            }, 200)

        } else {
            menu.classList.add('hidden')

        }
    }

    const ToggleFSMenu = () => {
        if (isMenuShow) setIsMenuShow(false)
        else setIsMenuShow(true)
    }

    return (
        <div className={'container m-auto'}>
            <div className={'hidden max-lg:flex justify-center mt-5 -mb-5'}>
                <Link to={'/'}><img src={logo} className={'h-[32px]'} /></Link>
            </div>
            <FullscreenMenu isMenuShow={isMenuShow} toggleAct={ToggleFSMenu} />
            <div className={'start-0 flex justify-between items-center p-5'}>
                <div className={'flex gap-3 items-center lg:w-1/3'}>
                    <i className='bx bx-menu cursor-pointer' onClick={ToggleFSMenu}></i>
                    <Link to={'/search'}><i className='bx bx-search'></i></Link>
                </div>
                <div className={'block max-lg:hidden lg:w-1/3 text-center'}>
                    <Link to={'/'}><img src={logo} className={'m-auto'}/></Link>
                </div>
                <div className={'lg:w-1/3 text-end'}>
                    <NavBarUserProfiler/>
                </div>
            </div>
            <div className={'absolute bg-black/50 p-4 text-white text-start hidden'} id={'headmenuMenu'}>
                <Link to={'/'}><p>Главная</p></Link>
                <Link to={'/search'}><p>Поиск</p></Link>
                <Link to={'/catalog'}><p>Каталог</p></Link>
            </div>
        </div>
    )
}