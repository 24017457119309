import {useEffect, useState} from "react";
import {API_ENDPOINT, MEDIA_ROOT} from "../constance";
import {Link} from "react-router-dom";
import axios from "axios";
import PrettyPrice from '../utils/PrettyPrice'
import LikeIcon from '../images/vectors/like.svg'
import LikeInactive from '../images/vectors/like_inactive.svg'
import Modal from "./ui/modal";
import Button from "./ui/button";

export default function ProductPreview(props) {

    const [data, setData] = useState(null)
    const [ok, setOk] = useState(false)
    const [isFavorite, setIsFavorite] = useState(false)



    useEffect(() => {
        if (props.productData === undefined) {
            let userId = localStorage.getItem('uid')
            axios.get(API_ENDPOINT + `product/get?item=${props.item}&uid=${userId}`)
                .then((resp) => {
                    setData(resp.data.data)
                    setIsFavorite(resp.data.favorite)
                    setOk(resp.data.ok)
                })
        } else {
            setOk(true)
            setData(props.productData)
        }

    }, []);

    const addToFavorites = () => {
        const token = localStorage.getItem('access_token')
        axios.post(API_ENDPOINT + "users/favorites/", {
            'item': data.item
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                let userId = localStorage.getItem('uid')

                axios.get(API_ENDPOINT + `product/get?item=${data.item}&uid=${userId}`)
                .then((resp) => {
                    setIsFavorite(resp.data.favorite)
                    setData(resp.data.data)
                    setOk(resp.data.ok)
                    if (props.updateList) {
                        props.updateList()
                    }
                })
            })
            .catch((err) => {
                if(err.response.status === 401) {
                    window.location = '/signin/'
                }
            })
    }


    return (
        <div className={'w-full'}>
            {
                data ? (
                    ok ?
                        (

                            <div className={'text-start'}>
                                <div className={'w-full aspect-square relative'}>
                                    <Link to={'/product/' + data.item} target={'_blank'}>
                                        <img src={MEDIA_ROOT + data.photos[0].image}
                                             className={'object-cover h-full w-full'}/></Link>
                                    {
                                        isFavorite === true || props.favorite ? (
                                            <img src={LikeIcon} className={'absolute top-0 end-0 m-5 cursor-pointer hover:scale-125 transition-all'} onClick={addToFavorites}/>
                                        ) : (
                                            <img src={LikeInactive} className={'absolute top-0 end-0 m-5 cursor-pointer hover:scale-125 transition-all'} onClick={addToFavorites}/>
                                        )
                                    }
                                </div>
                                <div className={'mt-4'}>
                                    <Link to={'/product/' + data.item} target={'_blank'}><h3
                                        className={'text-xl max-lg:text-lg'}>{data.title}</h3></Link>

                                    <p className={'text-md max-lg:text-sm'}>{PrettyPrice(data.price)}</p>

                                </div>
                            </div>

                        ) : (
                            <div className={'w-lg'}>
                                <div className={'w-full aspect-square bg-neutral-100'}>

                                </div>
                                <div className={'mt-4 bg-neutral-100 w-full'}>
                                    Товар не найден
                                </div>
                            </div>
                        )
                ) : (
                    <div className={'w-lg'}>
                        <div className={'w-full aspect-square bg-neutral-100'}>

                        </div>
                        <div className={'mt-4 bg-neutral-100 w-full h-32'}>

                        </div>
                    </div>
                )

            }

        </div>
    )
}