import FadedButton from "./buttonFaded";
import {Transition} from "react-transition-group";

export default function Modal({isOpen, title, onClose, children}) {
    return (
        <>
            {
                isOpen && (
                    <div
                        className={'fixed top-0 start-0 w-screen h-screen bg-black flex justify-center items-center bg-opacity-50'}>
                        <div className={'bg-white p-10 w-2/3 max-lg:w-full relative'}>
                            <h3 className={'text-6xl'}>{title}</h3>
                            {children}
                            <span className={'absolute top-0 end-0 m-5 cursor-pointer'} onClick={onClose}>X</span>
                        </div>
                    </div>
                )
            }
        </>
    )
}