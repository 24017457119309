import {useState} from "react";

export default function SelectableMenuItem(props) {

    const [isSelected, setIsSelected] = useState(false)
    const [index, setIndex] = useState(0)
    const parentElem = props.parentMenu

    const select = () => {
        setIsSelected(true)
    }

    const unselect = () => {
        setIsSelected(false)
    }

    const handleClick = () => {
        props.action(props.ind)
    }

    return (
        <div className={'cursor-pointer text-neutral-500 hover:text-black transition-all'} onClick={handleClick}>
            {props.value}
        </div>
    )
}