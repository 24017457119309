export default function ChatMessageBox(props) {
    return (
        <div className={'flex mb-3 ' + (props.data.author == props.currentUserId ? "justify-end" : "justify-start")}>
            <div>
                <div className={(props.data.author == props.currentUserId ? "text-end" : "text-start")}>
                    <p className={'text-sm text-neutral-400'}>{new Date(props.timestamp).toLocaleDateString('ru-ru')} в {new Date(props.timestamp).toLocaleTimeString('ru-ru')}</p>
                </div>
                <div className={'p-5 bg-neutral-200 ' + (props.data.author == props.currentUserId ? "ms-10" : "me-10")}>
                    <p>{props.data.message}</p>
                </div>
            </div>
        </div>
    )
}