import logo from '../../images/DEAL LOGO.svg'
import ConfedencePdf from '../../docs/condefance_deal.pdf'
import OfferPdf from '../../docs/offer_deal.pdf'
import CookiePdf from '../../docs/cookie_deal.pdf'

export default function Footer() {
    return (
        <footer className="bg-neutral-100 text-black mt-10 max-lg:mt-0">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between items-center">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <img src={logo} className="h-8 me-3"
                                 alt="DEAL Logo"/>
                            <span
                                className="self-center text-2xl font-semibold whitespace-nowrap">fashion</span>
                        </a>
                    </div>

                    <span className={'text-gray-500 text-sm flex gap-5'}>

                        <a href={ConfedencePdf} target={'_blank'}>Политика конфеденциальности</a>
                        <a href={CookiePdf}>Обработка куки</a>
                        <a href={OfferPdf} target={'_blank'} title={'Публичная оферта Deal'}>Публичная оферта</a>

                    </span>
                </div>
                <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Deal™. Все права защищены.
          </span>

                    <div className="flex mt-4 sm:justify-center sm:mt-0 gap-5 text-lg">
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <i className='bx bxl-telegram'></i>
                            <span className="sr-only">Telegram</span>
                        </a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <i className='bx bxl-whatsapp-square'></i>
                            <span className="sr-only">WhatsApp</span>
                        </a>
                        <a href="#" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <i className='bx bxl-instagram-alt'></i>
                            <span className="sr-only">Instagram</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>

    )
}