import {Link, useParams} from "react-router-dom";
import HeadMenu from "../components/ui/headmenu";
import {API_ENDPOINT, MEDIA_ROOT} from "../constance";
import PrettyPrice from "../utils/PrettyPrice";
import ToCartButton from "../components/ui/AddToCartButton";
import Button from "../components/ui/button";
import {useEffect, useState} from "react";
import axios from "axios";
import ProductInCart from "../components/ui/ProductCartView";

export default function OrderDetails() {

    const {orderId} = useParams()
    const [orderData, setOrderData] = useState(null)

    useEffect(() => {
        document.title = 'Детали заказа'
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/order/?orderId=" + orderId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setOrderData(resp.data)
                console.log(resp.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    return (
        <div>
            <div className="App container m-auto z-10 text-start">
                <HeadMenu/>
                <div className={'text-start'}>
                    <h1 className={'text-5xl'}>Детали заказа №{orderData ? orderData.id : 0}</h1>
                    <div className={'mt-2 flex max-lg:flex-col gap-5 justify-between text-start items-start max-lg:p-5'}>
                        {
                            orderData ? (
                                <div className={'flex flex-col gap-5 grow'}>
                                    {
                                        orderData.details.map((value) =>
                                            <ProductInCart data={value} key={`${value.id}${value.size}`}/>
                                        )
                                    }
                                </div>
                            ) : (
                                <p>Нет прав на просмотр заказа</p>
                            )
                        }
                        {
                            orderData ? (
                                <div className={'w-1/4 max-lg:w-full border-2 border-neutral-300 p-5 text-start'}>
                                    <h3 className={'font-bold mb-5'}>Детали заказа</h3>
                                    <div className={'flex justify-between items-center'}>
                                        <p className={'font-bold'}>ID</p>
                                        <p>{orderData.id}</p>
                                    </div>
                                    <div className={'flex justify-between items-center'}>
                                        <p className={'font-bold'}>Статус</p>
                                        <p>{orderData.status}</p>
                                    </div>
                                    <div className={'flex justify-between items-center'}>
                                        <p className={'font-bold'}>Дата создания</p>
                                        <p>{new Date(orderData.creation_date).toLocaleDateString('ru-ru')}</p>
                                    </div>
                                </div>
                            ) : (
                                <p></p>
                            )
                        }

                    </div>
                </div>


            </div>
        </div>

    )
}