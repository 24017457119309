import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, createBrowserRouter, Route, RouterProvider} from "react-router-dom";
import ProductDetails from "./pages/ProductDetails";
import SignUp from "./pages/SignUp";
import Search from "./pages/Search";
import axios from "axios";
import SignIn from "./pages/SignIn";
import Cart from "./pages/Cart";
import Personal from "./pages/Personal";
import Chat from "./pages/Chat";
import BlogPost from "./pages/BlogPost";
import Catalog from "./pages/Catalog";
import OrderDetails from "./pages/OrderDetails";
import Newest from "./pages/newest";

const userRouter = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: '/product/:productItem/',
        element: <ProductDetails/>
    },
    {
        path: '/signup',
        element: <SignUp/>
    },
    {
        path: '/signin',
        element: <SignIn/>
    },
    {
        path: '/search',
        element: <Search/>
    },
    {
        path: '/cart',
        element: <Cart/>
    },
    {
        path: '/personal',
        element: <Personal/>
    },
    {
        path: '/chat/:chatId/',
        element: <Chat/>
    },
    {
        path: 'blog/:postId/',
        element: <BlogPost />
    },
    {
        path: 'catalog/',
        element: <Catalog />
    },
    {
        path: 'order/:orderId/',
        element: <OrderDetails />
    },
    {
        path: 'newest/',
        element: <Newest />
    }
]);

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const r = '4423jc'


const root = ReactDOM.createRoot(document.getElementById('root'));

const subdomain = window.location.host.split('.')[0]
console.log('sub')
console.log(subdomain)


root.render(
    <RouterProvider router={userRouter}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
