import {useState} from "react";

export default function FiltersGroup(props) {

    const [folded, setFolded] = useState(true)

    const switchFolded = () => {
        setFolded(!folded)
    }

    const selectAll = (src) => {
        const inputs = Array.prototype.slice.call(document.querySelector(`#${props.name}_filtergroup`).getElementsByTagName('input'))
        let selected = 0
        inputs.forEach((input) => {
            if (input.id !== `${props.name}_filtergroup`) {
                if (input.checked) {
                    selected += 1
                }
            }
        })

        if (selected > 0 && selected < inputs.length-1 || selected === 0) {
            inputs.forEach((input) => {
                if (input.id !== `${props.name}_filtergroup`) {
                    input.checked = true
                }
                else {
                    input.checked = true
                }
            })
        }

        if (selected === inputs.length-1) {
            inputs.forEach((input) => {
                if (input.id !== `${props.name}_filtergroup`) {
                    input.checked = false
                }
                else {
                    input.checked = false
                }
            })
        }
    }

    return (
        <div className="container mx-auto">
            <div className="relative p-3 border border-neutral-200 mt-2" id={`${props.name}_filtergroup`}>

                <div className={(folded ? 'hidden' : 'block')}>
                    <div>
                        <input type={'checkbox'} id={`select_all_${props.name}_filtergroup`}
                               className={'filter-checkbox m-1'} onChange={() => selectAll(this)}/>
                        <label htmlFor={`select_all_${props.name}_filtergroup`}>Выбрать все</label>
                    </div>
                    {props.children}
                </div>


                <h2 className={"absolute flex top-0 transform -translate-y-1/2 cursor-pointer"} onClick={switchFolded}>
                    <span className="bg-white font-medium">
                        {props.name}

                        <span >
                            {
                                folded ? (
                                    <i className='bx bx-chevron-down'></i>
                                ) : (
                                    <i className='bx bx-chevron-up'></i>
                                )
                            }
                        </span>


                    </span>
                </h2>
            </div>
        </div>
    )
}