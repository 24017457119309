import ProductPreview from "../product_preview";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_ENDPOINT} from '../../constance'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import leftArrow from '../../images/vectors/arrow-left.svg'

export default function Showcase({showcaseId}) {

    const [products, setProducts] = useState([])
    const cor = useRef()


    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div
                className={'slick-arrow text-yellow-400 slick-prev text-transparent bg-clip-text'}
                style={{...style, display: "block", background: "red", color: "red"}}
                onClick={onClick}
            >

            </div>
        );
    }

    useEffect(() => {
        axios.get(API_ENDPOINT + 'showcases/?sid=' + String(showcaseId))
            .then((resp) => {
                setProducts(resp.data)
            })
        document.querySelector('#next-btn').onclick = function () {
            cor.current.slickNext()
        }

        document.querySelector('#prev-btn').onclick = function () {
            cor.current.slickPrev()
        }
    }, []);

    const sliderSettings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        className: ''
    }

    return (
        <div className={'lg:grid lg:gap-4 lg:grid-cols-[5%_90%_5%]'}>
            <button className={'text-5xl max-lg:hidden'} id={'prev-btn'}><i className='bx bx-chevron-left'></i></button>
            <Slider {...sliderSettings} ref={cor}>
                {
                    products.map((item) =>
                        <ProductPreview item={item}/>
                    )
                }
            </Slider>
            <button className={'text-5xl max-lg:hidden'} id={'next-btn'}><i className={'bx bx-chevron-right'}></i></button>
        </div>

        // <div className={'flex max-lg:flex-col justify-around gap-10'}>

        // </div>
    )
}