import HeadMenu from "../components/ui/headmenu";
import ProductPreview from "../components/product_preview";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT} from "../constance";
import Footer from "../components/ui/footer";

export default function Newest() {

    const [catalog, setCatalog] = useState([])

    const GetCatalog = () => {
        setCatalog(null)
        // alert(filters)
        const minPrice = document.querySelector('#minPriceFilter').value
        const maxPrice = document.querySelector('#maxPriceFilter').value
        axios.get(API_ENDPOINT + `catalog/?newest=true`)
            .then((resp) => {
                setCatalog(resp.data)
            })
    }

    useEffect(() => {
        setCatalog(null)
        // alert(filters)
        axios.get(API_ENDPOINT + `catalog/?newest=true`)
            .then((resp) => {
                setCatalog(resp.data)
            })
    }, []);

    return (
        <div>
            <div className="App container m-auto z-10">
                <HeadMenu/>
                <div className={'text-start max-lg:p-5'}>
                    <h1 className={'text-4xl'}>Новинки текущего сезона</h1>
                    <p>Здесь вы можете ознакомиться с новыми предложениями и коллекциями этого сезона</p>
                </div>
                <div className={'max-lg:w-full text-start mt-10 max-lg:p-5'}>
                    <div className={'grid grid-cols-4 max-lg:grid-cols-2 max-lg:grid-cols-1 gap-4'}>
                        {
                            catalog ? (
                                catalog.map((d) =>
                                    <ProductPreview productData={d}/>
                                )
                            ) : (
                                <p>Ошибка</p>
                            )
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}