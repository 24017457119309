import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT} from "../constance";
import HeadMenu from "../components/ui/headmenu";
import TextInput from "../components/ui/TextInput";
import Button from "../components/ui/button";
import ProductPreview from "../components/product_preview";
import {useParams} from "react-router-dom";
import HorizontalRow from "../components/ui/HorizontalRow";
import RichTextInput from "../components/ui/RichTextInput";
import ChatMessageBox from "../components/ui/Chats/ChatMessageBox";

export default function Chat() {

    const {chatId} = useParams()
    const [chatHistory, setChatHistory] = useState({})
    const [chatData, setChatData] = useState({})
    const userId = localStorage.getItem('uid')

    useEffect(() => {
        document.title = 'Чат с поддержкой'
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "chats/?chatId=" + chatId, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setChatHistory(resp.data.history)
                setChatData(resp.data)
                console.log(resp.data)
            })
            .catch((err) => {
                alert(err)
            })

        setInterval(() => {
            axios.get(API_ENDPOINT + "chats/?chatId=" + chatId, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((resp) => {
                    setChatHistory(resp.data.history)
                    setChatData(resp.data)
                    console.log(resp.data)
                })
                .catch((err) => {
                    alert(err)
                })
        }, 1000)

    }, []);

    // useEffect(() => {
    //     const chatZone = document.querySelector('#chatZone')
    //
    //     chatZone.innerHTML = ''
    //
    //     console.log(chatHistory)
    //
    //     Object.keys(chatHistory).forEach((timestamp) => {
    //         console.log(timestamp)
    //         console.log(chatHistory[timestamp])
    //
    //         let messageHolder = document.createElement('div')
    //         messageHolder.classList.add('flex', 'mb-3', 'w-full')
    //         let messageBox = document.createElement('div')
    //         messageBox.innerText = chatHistory[timestamp].message
    //
    //         if (chatHistory[timestamp].author == userId) messageHolder.classList.add('justify-end')
    //         else messageHolder.classList.add('justify-start')
    //
    //         messageBox.classList.add('p-5', 'bg-neutral-200')
    //
    //         messageHolder.appendChild(messageBox)
    //         chatZone.appendChild(messageHolder)
    //     })
    // }, [chatHistory]);

    const SendMessage = () => {
        const content = document.querySelector('#messageInput')

        const token = localStorage.getItem('access_token')
        axios.post(API_ENDPOINT + "chats/sendmessage/", {
            chatId: chatId,
            content: content.value
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                content.value = ''
                axios.get(API_ENDPOINT + "chats/?chatId=" + chatId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((resp) => {
                        setChatHistory(resp.data.history)
                        console.log(resp.data)
                    })
                    .catch((err) => {
                        alert(err)
                    })
            })
    }

    return (
        <div className={'container m-auto max-h-screen'}>
            <HeadMenu/>

            <div className={'flex flex-col justify-center w-1/3 m-auto max-lg:w-full max-lg:p-5'}>
                <div>
                    <h1 className={'text-2xl'}>Чат со службой поддержки</h1>
                    <p>Тема обращения: {chatData.title}</p>
                </div>
                <div className={'border-2 border-neutral-300 w-full mt-5 m-auto overflow-auto h-[calc(100vh-450px)]'}>
                    {
                        chatHistory ? (
                            Object.keys(chatHistory).map((timestamp) =>
                                <ChatMessageBox data={chatHistory[timestamp]} timestamp={timestamp}
                                                currentUserId={userId}/>
                            )
                        ) : (
                            <p>История чата пуста</p>
                        )
                    }
                </div>
                <div className={'flex flex-col justify-center'}>
                    <RichTextInput inputId={'messageInput'} hint={'Ваше сообщение'}/>
                    <Button value={'Отправить'} action={SendMessage}/>
                </div>
            </div>

            {/*{*/}
            {/*    chatHistory !== {} ? (*/}
            {/*        <div className={'border-2 flex flex-col h-1/2 border-2 border-red-400'}>*/}
            {/*            <div className={'flex flex-col justify-between overflow-auto'} id={'chatZone'}>*/}
            {/*                {*/}
            {/*                    chatHistory ? (*/}
            {/*                        Object.keys(chatHistory).map((timestamp) =>*/}
            {/*                            <ChatMessageBox data={chatHistory[timestamp]} timestamp={timestamp}*/}
            {/*                                            currentUserId={userId}/>*/}
            {/*                        )*/}
            {/*                    ) : (*/}
            {/*                        <p>История чата пуста</p>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <HorizontalRow>*/}
            {/*                    <RichTextInput inputId={'messageInput'} hint={'Ваше сообщение'}/>*/}
            {/*                    <Button value={'Отправить'} action={SendMessage}/>*/}
            {/*                </HorizontalRow>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ) : (*/}
            {/*        <p>Такого чата не существует. Возможно, вы ошиблись.</p>*/}
            {/*    )*/}
            {/*}*/}
        </div>
    )
}