import {useState, useEffect} from 'react';
import axios from 'axios';
import {API_ENDPOINT} from "../../constance";

const useAuth = () => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            try {
                axios.post(API_ENDPOINT + 'token/refresh/', {
                    refresh: refreshToken
                })
                    .then((response) => {
                        localStorage.setItem('refresh_token', response.data.refresh);
                        localStorage.setItem('access_token', response.data.access);
                        setAuthenticated(true);
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            } catch (error) {
                console.error(error);
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_token');
                setAuthenticated(false);
            }
        } else {
            setAuthenticated(false);
        }
    }, []);

    const login = (username, password) => {
        try {
            axios.post(API_ENDPOINT + 'users/signin/', {
                username,
                password
            })
                .then((response) => {
                    const {refresh, access} = response.data;
                    localStorage.setItem('refresh_token', refresh);
                    localStorage.setItem('access_token', access);
                    setAuthenticated(true);
                })
        } catch (error) {
            console.error(error);
        }
    };

    const logout = () => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        setAuthenticated(false);
    };

    return {
        authenticated,
        login,
        logout
    };
};

export default useAuth;