export default function TextInput(props) {
    return (
        <div className={'text-start mt-2 mb-2 w-full'}>
            {
                props.hasLabel ? (
                    <p>{props.hint}</p>
                ) : (
                    <></>
                )


            }
            <input onChange={props.onChange} id={props.inputId} type={'text'} placeholder={props.hint} className={'p-2 border w-full'} value={props.initValue} disabled={props.readonly}/>
        </div>
    )
}